/**
 * Blog article template
 * @param {object} article
 * @param {string} modifierBlock - classnames
 * @return {string} - Formatted HTML
 */
const blogArticleTpl = function (article, modifierBlock) {

  /*************************************************************************/
  /*** détermination de tous les éléments à concaténer dans le cartouche ***/
  /*************************************************************************/
  const strDate = genericDate.toDisplay(article.article_date.date);
  const gaLabel = article.article_title.replace(/'/, '’');

  // CONCATENATION DE TOUT LE CONTENU
  return `
    <div class="CommonContentBlock r-Grid-cell ${modifierBlock} overviewThumb-container masonryBox">
      <a class="overviewThumb-picture js-Tracking--link" href="${article.article_url}" target="_blank" data-tracking='{"clickCode":"clickLinkPictureArticleBlog","gaCategory":"${pageTemplate}","gaAction":"Article","gaLabel":"${gaLabel}","gaValue":1}'>
        <picture>
          <img alt="${article.article_title}" itemprop="image" data-src="${article.article_cover_url}" class="lazy">
        </picture>
      </a>
      <div class="CommonContentBlock-content overviewThumb-content">
        <div class="overviewThumb-heart">
          <iframe class="blogArticle_like" src="${article.like_url}" frameborder="0" scrolling="no" allowTransparency="true"></iframe>
        </div>
        <p class="overviewThumb-category">${article.section_name}</p>
        <h2 class="h4-like"><a class="blogLink" href="${article.article_url}" target="_blank">${article.article_title}</a></h2>
        <div class="CommonContentBlock-texts">
          <div class="CommonContentBlock-text overviewThumb-text">
            <p><a class="blogLink js-Tracking--link" href="${article.article_url}" target="_blank" data-tracking='{"clickCode":"clickLinkTitleArticleBlog","gaCategory":"${pageTemplate}","gaAction":"Article","gaLabel":"${gaLabel}","gaValue":1}'>${article.article_summary}</a></p>
          </div>
        </div>
        <div class="CommonContentBlock-button overviewThumb-button">
          <a class="js-buttonLoader buttonContainer js-Tracking--link" href="${article.article_url}" target="_blank" data-tracking='{"clickCode":"clickLinkTextArticleBlog","gaCategory":"${pageTemplate}","gaAction":"Article","gaLabel":"${gaLabel}","gaValue":1}'>
            <span class="animatedIconContainer">
              <i class="icon icon-arrow_cta"></i>
            </span>
            <span class="button button--transparentBlack button--primary button--withIcon">
              ${strDate}
            </span>
          </a>
        </div>
      </div>
    </div>`;
};

export {blogArticleTpl};